import "./App.css";
import { useState } from "react";
import { Landing } from "./components/landing/landing";
import { Login } from "./components/login/login";
import { Video } from "./components/video/video";

function App() {
  const [currentView, setCurrentView] = useState("landing");
  const [videoUrl, setVideoUrl] = useState(null);

  function viewLogin() {
    setCurrentView("login");
  }

  function loadVideo(url) {
    setVideoUrl(url);
    setCurrentView("video");
  }
  return (
    <div className="App">
      <div className="App-header">
        {currentView === "landing" && <Landing viewLogin={viewLogin} />}
        {currentView === "login" && <Login onReceiveUrl={loadVideo} />}
        {currentView === "video" && <Video url={videoUrl} />}
      </div>
    </div>
  );
}

export default App;
