import { Button, TextField, Typography } from "@mui/material";
import { TransparentContainer } from "../transparentContainer/transparentContainer";
import { getSignedUrl } from "../../api";
import { useState } from "react";
import "./login.css";

export const Login = ({ onReceiveUrl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  async function login(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(false);
    const { username, password } = event.currentTarget.elements;
    const response = await getSignedUrl(username.value, password.value);
    if (response?.url) {
      onReceiveUrl(response.url);
    } else {
      setError("Invalid credentials");
      setIsLoading(false);
    }
  }
  return (
    <div>
      <TransparentContainer>
        <form onSubmit={login}>
          <div className="login-form">
            <TextField
              id="username"
              label="User"
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              autoComplete="off"
            />
            <Button disabled={isLoading} variant="contained" type="submit">
              {isLoading ? <div>Let's see...</div> : "What could it be?"}
            </Button>
            {error && (
              <Typography className="error" variant="h6">
                Whoops! Try again!
              </Typography>
            )}
          </div>
        </form>
      </TransparentContainer>
    </div>
  );
};
