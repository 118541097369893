import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TransparentContainer } from "../transparentContainer/transparentContainer";

export const Landing = ({ viewLogin }) => {
  return (
    <div>
      <TransparentContainer>
        <Typography variant="h2">Welcome, Pepi</Typography>
        <Button variant="contained" onClick={viewLogin}>
          Let's go!
        </Button>
      </TransparentContainer>
    </div>
  );
};
