const lambdaUrl =
  "https://74j2ohat4ep3uuhgkxou7537w40mucki.lambda-url.us-east-1.on.aws/";

async function getSignedUrl(username, password) {
  return fetch(
    `${lambdaUrl}/getBreeCameo?objectKey=kat_graham_cameo.mp4&username=${username}&password=${password}`
  ).then(
    (res) => res.json(),
    (err) => console.log(err)
  );
}

export { getSignedUrl };
